import { Box, Heading, Image, Paragraph } from "grommet";
import React from "react";
import "../assets/styles/styles.scss";

function PageTitle({ title, image, body, fill = false, isMobile = false }) {
  return (
    <Box
      align="center"
      width={fill ? "900px" : "700px"}
      alignSelf="center"
      flex={false}>
      <Image
        src={image}
        width={isMobile ? "auto" : "144px"}
        height={isMobile ? "48px" : "auto"}
      />
      <Heading
        textAlign="center"
        level={1}
        fill={fill}
        style={{ maxWidth: fill ? null : 500 }}>
        {title}
      </Heading>
      <Paragraph fill textAlign="center">
        {body}
      </Paragraph>
    </Box>
  );
}

export default PageTitle;
