import React, { useEffect, useState } from "react";
import { Box, RadioButton, FormField, Text } from "grommet";
import styled from "styled-components";
import { Controller, useController } from "react-hook-form";
import { useSelector } from "react-redux";

const StyledRadio = styled(Box)`
  border: 1px solid #0030571f;
  padding: 21px;
  width: auto;
  align-self: stretch;
  flex: 1;
  flex-direction: row;
`;

function TextInputComponent({
  name,
  label,
  options,
  info,
  direction = "column",
  control,
  setValue,
}) {
  const defaultValue = useSelector((state) => state.form[name]);
  const [checked, setChecked] = useState(defaultValue);

  const {
    field: { ref, onChange, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required: true },
    defaultValue,
  });

  const onValueChange = (e, index) => {
    setChecked(index);
    setValue(name, e.target.value, { shouldTouch: true, shouldValidate: true });
  };

  return (
    <>
      <Box
        style={{ border: `2px solid ${error ? "#CE614A" : "transparent"}` }}
        error={error}
        direction={direction}
        gap={direction === "column" ? "small" : "medium"}
        margin={{ top: "25px" }}>
        {options.map((option, index) => {
          return (
            <StyledRadio key={index}>
              <RadioButton
                ref={ref}
                name="radio"
                value={option}
                checked={checked === option}
                label={option}
                onChange={(event) => onValueChange(event, option)}
              />
            </StyledRadio>
          );
        })}
      </Box>
      {error && (
        <Text style={{ fontSize: 12, color: "#CE614A", margin: 0 }}>
          {"required"}
        </Text>
      )}
    </>
  );
}

export default TextInputComponent;
