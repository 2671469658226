import { Box, Image, Paragraph, ResponsiveContext } from "grommet";
import React, { useContext } from "react";
import logoMarque from "../assets/images/logo_marque_blue.svg";
import "../assets/styles/styles.scss";
import HorizontalRule from "../components/HorizontalRule";

function Footer() {
  const size = useContext(ResponsiveContext);
  const isMobile = size === "small" || size === "xsmall" ? true : false;

  return (
    <Box
      flex={false}
      fill="horizontal"
      align="center"
      pad={{ bottom: isMobile ? "100px" : "none" }}>
      <HorizontalRule />
      <Image width="57px" src={logoMarque} />
      {isMobile ? (
        <>
          <Paragraph fill margin={{top: "medium", bottom:"xsmall"}} className="smallPrint straight" textAlign="center">
            Carriage House © 2021 / All rights reserved
          </Paragraph>
          <Paragraph fill  margin="xsmall" className="smallPrint straight" textAlign="center">
            <a href="https://carriagehousepb.com/terms-and-conditions/">
              Terms and Conditions
            </a>
          </Paragraph>
          <Paragraph fill  margin="xsmall" className="smallPrint straight" textAlign="center">
            <a href="https://carriagehousepb.com/privacy-policy/">
              Privacy Policy
            </a>
          </Paragraph>
          <Paragraph fill  margin="xsmall" className="smallPrint straight" textAlign="center">
            <a href="https://carriagehousepb.com/Agent-Designation-Form.docx">
              Designation Form
            </a>
          </Paragraph>
        </>
      ) : (
        <Paragraph fill className="smallPrint straight" textAlign="center">
          Carriage House © 2021 / All rights reserved /
          <a href="https://carriagehousepb.com/terms-and-conditions/">
            Terms and Conditions
          </a>
          /
          <a href="https://carriagehousepb.com/privacy-policy/">
            Privacy Policy
          </a>
          /
          <a href="https://carriagehousepb.com/Agent-Designation-Form.docx">
            Designation Form
          </a>
        </Paragraph>
      )}
    </Box>
  );
}

export default Footer;
