import { Box } from "grommet";
import React from "react";
import "../assets/styles/styles.scss";


function HorizontalRule() {
    return (
      <Box
        margin={{ vertical: "36px" }}
        fill="horizontal"
        border={{ side: "bottom", size: "1px", color: "grey" }}></Box>
    );
  }

export default HorizontalRule;