import React, { useContext } from "react";
import { Box, Heading, Grid } from "grommet";
import BackButton from "../components/BackButton";
import { ResponsiveContext } from "grommet";
import { useSelector, useDispatch } from "react-redux";
import { goBack } from "../state/globalSlice";

const FormBlockComponent = ({
  title,
  margin = null,
  children,
  backButton = false,
  ...rest
}) => {
  const dispatch = useDispatch();
  const size = useContext(ResponsiveContext);
  const isMobile = size === "small" || size === "xsmall" ? true : false;

  const columns = isMobile ? ["flex"] : ["flex", "flex"];
  return (
    <>
      <Grid
        columns={columns}
        gap={isMobile ? "none" : "large"}
        margin={margin}
        {...rest}>
        <Box>
          {backButton && !isMobile ? (
            <BackButton onClick={() => dispatch(goBack())} />
          ) : (
            <Heading
              level={4}
              size={isMobile ? "21px" : "25px"}
              margin={isMobile ? { top: "36px", bottom: "none" } : null}>
              {title}
            </Heading>
          )}
        </Box>
        <Box>{children}</Box>
      </Grid>
    </>
  );
};

export default FormBlockComponent;
