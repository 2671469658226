import { Box, Heading, Text, ResponsiveContext } from "grommet";
import React, { useEffect, useRef, useContext, Fragment } from "react";
import HeaderLogo from "../assets/images/logo_white.inline.svg";
import "../assets/styles/styles.scss";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const Number = ({ active, number, label, mobile=false }) => {
  return (
    <Box align="center" style={{ position: "relative" }}>
      <Box
        round="full"
        background="#FFFFFF"
        style={{ opacity: active ? 1 : 0.5 }}
        height="32px"
        width="32px"
        margin={{ bottom: mobile ? "none" : "10px" }}
        align="center"
        justify="center">
        <Heading level={3} size="14px" color="brand">
          {number}
        </Heading>
      </Box>
      {!mobile && (
        <Text
          style={{ position: "absolute", top: 35, opacity: active ? 1 : 0.5 }}
          size="12px"
          color="white">
          {label}
        </Text>
      )}
    </Box>
  );
};

const Line = ({ active }) => {
  return (
    <Box
      style={{ opacity: active ? 1 : 0.5 }}
      width={{ min: "2px", max: "90px" }}
      flex="grow"
      height="1px"
      background="white"></Box>
  );
};

const NumberBar = ({ activeSection, mobile = false }) => {
  const sections = ["Basics", "References", "Proposer", "Review", "Complete"];

  return (
    <Box
      height="70px"
      align="center"
      background="brand"
      style={mobile ? { position: "fixed", bottom: 0, zIndex: 1000 } : null}
      direction="row"
      width={{ max: "700px" }}
      justify="between"
      fill="horizontal"
      pad={{ horizontal: "25px" }}
      margin={{ top: "25px" }}>
      {sections.map((section, index) => {
        return (
          <Fragment key={index}>
            <Number
              mobile={mobile}
              number={index + 1}
              label={section}
              active={activeSection >= index + 1}
            />
            {index !== sections.length - 1 && (
              <Line active={activeSection >= index + 2} />
            )}
          </Fragment>
        );
      })}
    </Box>
  );
};

const PageHeader = ({ activeSection = 2, isSticky }) => {
  const headerRef = useRef(null);
  const size = useContext(ResponsiveContext);
  const isMobile = size === "small" || size === "xsmall" ? true : false;
  const mobileRef = useRef();
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    mobileRef.current = isMobile;
  }, [isMobile]);

  useEffect(() => {
    //quick fix as the mobile context initial value is wrong
    setTimeout(() => {
      !mobileRef.current &&
        ScrollTrigger.create({
          trigger: headerRef.current,
          start: "center top",
          endTrigger: "html",
          end: "bottom top",
          pin: headerRef.current,
        });
    }, [500]);
  }, []);

  return (
    <>
      <Box
        ref={headerRef}
        fill="horizontal"
        background="brand"
        height={isMobile ? "72px" : "171px"}
        align="center"
        justify="center"
        style={{
          position: isSticky ? "fixed" : "absolute",
          top: isSticky && !isMobile ? -80 : 0,
          zIndex: 10,
        }}>
        <HeaderLogo
          width={isMobile ? "75px" : "100px"}
          style={{ top: isMobile ? 0 : 15, position: "relative" }}
        />
        {!isMobile && <NumberBar activeSection={activeSection} />}
      </Box>
      {isMobile && <NumberBar mobile activeSection={activeSection} />}
    </>
  );
};

export default PageHeader;
