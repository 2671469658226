import React, { useState } from "react";
import { Box, Button, Text } from "grommet";
import styled from "styled-components";
import { LinkNext } from "grommet-icons";

function ButtonComponent({
  color = null,
  text,
  multiline = false,
  icon,
  download=false,
  ...rest
}) {
  return (
    <Button className="button" style={{ flex: 1 }} {...rest}>
      <Box
        flex
        alignSelf="stretch"
        height={download ? "72px" : "56px"}
        background={color}
        border={{ side: "all", size: "1px", color: color ? color : "grey" }}
        direction="row"
        justify="between"
        align="center"
        style={{
          padding: "5px 24px",
          marginBottom: 16,
          textTransform: "uppercase",
        }}>
        <Text
          color={color ? "white" : "brand"}
          style={{
            fontFamily: "Americana",
            fontSize: "14px",
            maxWidth: multiline ? 150 : null,
            lineHeight: 1,
          }}>
          {text}
        </Text>
        {icon}
      </Box>
    </Button>
  );
}

export default ButtonComponent;
