import React, { useState } from "react";
import { Box, Button, Text } from "grommet";
import styled from "styled-components";
import { LinkNext } from "grommet-icons";
import BackArrow from "../assets/images/ic_arrow_back.inline.svg";

function ButtonComponent({
  color = null,
  text,
  multiline = false,
  icon,
  mobile = false,
  ...rest
}) {
  return (
    <Button className="button" alignSelf={"start"} plain {...rest}>
      <Box
        width={"135px"}
        alignSelf="stretch"
        height="56px"
        background={"brand"}
        border={{ side: "all", size: "1px", color: color ? color : "grey" }}
        direction="row"
        justify="between"
        align="center"
        style={{
          padding: "5px 24px",
          marginBottom: 16,
          textTransform: "uppercase",
        }}>
        {<BackArrow color="white" />}

        <Text
          color={"white"}
          style={{
            fontFamily: "Americana",
            fontSize: "14px",
            maxWidth: multiline ? 150 : null,
            lineHeight: 1,
          }}>
          {"Back"}
        </Text>
      </Box>
    </Button>
  );
}

export default ButtonComponent;
